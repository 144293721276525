:root {
	--primary: #1888ff;
}

.btn {
	font-family: 'Plus Jakarta Sans', sans-serif;
	padding: 10px 20px;
	border-radius: 4px;
	outline: none;
	border: none;
	font-size: 16px;
	color: #3f4ff8;
	cursor: pointer;
	background-color: #fff;
	border-radius: 100px;
	border: 1px solid #3f4ff8;
	font-weight: bold;
}

.btn:hover {
	/* padding: 6px 18px;
	transition: all 0.3s ease-out;
	background-color: transparent;
	color: #fff;
	border-radius: 4px;
	border: 2px solid var(--primary);
	transition: all 0.3s ease-out; */
}
