.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@font-face {
	font-family: 'Plus Jakarta Sans', sans-serif;
	src: url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
}

.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
	display: flex;
	height: 90vh;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
}

.home {
	background-image: url('./assets/images/img-1.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.products {
	background-image: url('./assets/images/img-3.jpg');
	background-position: center;
	background-size: fill;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.contact-us {
	background-image: url('./assets/images/img-4.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.sign-up {
	background-image: url('./assets/images/img-7.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.marketing {
	background-image: url('./assets/images/img-6.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.consulting {
	background-image: url('./assets/images/img-5.jpg');
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

span,
label,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.not_found_container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70vh;
	width: 60vh;
}

.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.Toastify {
	.Toastify__toast-container {
		width: 400px;
		.Toastify__toast {
			.Toastify__toast-body {
				.push-notification {
					display: flex;
					&.SUCCESS {
						.indicator {
							background: #21a47a;
						}
						.icon {
							color: #21a47a;
						}
					}
					&.INFO {
						.indicator {
							background: #0a74b5;
						}
						.icon {
							color: #0a74b5;
						}
					}
					&.ERROR {
						.indicator {
							background: #ea2a6b;
						}
						.icon {
							color: #ea2a6b;
						}
					}
					&.WARNING {
						.indicator {
							background: #eba631;
						}
						.icon {
							color: #eba631;
						}
					}
					.indicator {
						height: auto;
						width: 1.5%;
						border-radius: 10px;
						margin-right: 10px;
					}
					.icon {
						display: flex;
						align-items: center;
						margin-right: 10px;
						font-size: 20px;
					}
					.push-notification-content {
						width: 98.5%;
						.push-notification-title {
							font-weight: bold;
							color: #000;
						}
						.push-notification-text {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

.headroom {
	top: 0;
	left: 0;
	right: 0;
	zindex: 1;
}
.headroom--unfixed {
	position: relative;
	transform: translateY(0);
}
.headroom--scrolled {
	transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
	position: fixed;
	transform: translateY(-100%);
}
.headroom--pinned {
	position: fixed;
	transform: translateY(0%);
	background-color: #fff;
}
