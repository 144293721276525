.top_section {
	background-image: url('../../assets/images/home-back.png');
	background-repeat: no-repeat;
	min-height: 50vh;
	padding: 100px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 768px) {
		background-image: none;
	}
	.container {
		font-family: 'Plus Jakarta Sans', sans-serif;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		.desc {
			font-size: 16px;
			text-align: center;
			width: 80%;
			color: #8181ac;
			margin: 0;
		}
		.title {
			font-family: 'Plus Jakarta Sans';
			font-size: 16px;
			font-weight: bold;
			text-align: center;
			letter-spacing: -0.02em;
			text-transform: uppercase;

			color: #3f4ff8;
		}
		h1 {
			font-size: 50px;
			text-align: center;
		}
		.animate_text_area {
			display: flex;
			align-items: center;
			width: 80%;
			.Typewriter {
				font-family: 'Plus Jakarta Sans';
				font-size: 50px;
				text-align: center;
				font-weight: bold;
				margin-left: 10px;
				color: #3f4ff8;
			}
		}
		.home_button {
			padding: 30px;
			font-size: 20px;
			border-radius: 35px;
			@media (max-width: 768px) {
				padding-top: 12px;
				padding-bottom: 12px;
				border-radius: 35px;
				font-size: 16px;
			}
		}
	}

	@media (max-width: 768px) {
		padding: 20px;

		.container {
			.title {
				font-size: 12px;
			}
			h1,
			.Typewriter__wrapper {
				font-size: 32px;
				text-align: center;
			}
			.animate_text_area {
				flex-direction: column;
			}
		}
	}
}
