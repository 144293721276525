.privacy{
    padding: 20px;
    color: #8181ac;
}
.privacy .title{
    font-size: 1.15rem;
    padding-top: 10px;
}
.privacy strong{
    font-size: 1rem;
}
.privacy span{
font-size: .9rem;
}