$dropdown-menu-background: #fff;
$dropdown-menu-width: 75%;
$dropdown-menu-position-top: 100px;
$dropdown-menu-position-left: 15%;
$dropdown-menu-list-style: none;
$dropdown-menu-text-align: start;
.dropdown-menu {
	background: $dropdown-menu-background;
	width: $dropdown-menu-width;
	position: absolute;
	top: $dropdown-menu-position-top;
	left: $dropdown-menu-position-left;
	display: flex;
	box-shadow: 0px 4px 10px 0px #28287640;
	border-bottom-left-radius: 40px;
	border-bottom-right-radius: 40px;
	z-index: 1;

	ul {
		list-style: $dropdown-menu-list-style;
		text-align: $dropdown-menu-text-align;
		padding: 40px;
		width: 30%;
		li {
			position: relative;
			cursor: pointer;
			transition: all 0.2s ease-out;
			&:hover {
				a {
					color: #3f4ff8;
				}
				&::after {
					content: '';
					position: absolute;
					left: -25px;
					top: 40%; /* Adjust this value as needed */
					width: 0;
					height: 0;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-bottom: 10px solid #3f4ff8; /* Color of the arrow */
					transform: rotate(90deg);
					z-index: 10;
				}
			}
			&::after {
				content: '';
				position: absolute;
				left: -25px;
				top: 40%; /* Adjust this value as needed */
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid #282876; /* Color of the arrow */
				transform: rotate(90deg);
				z-index: 10;
			}
		}

		&.clicked {
			display: none;
		}

		.dropdown-link {
			display: block;
			height: 100%;
			width: 100%;
			text-decoration: none;
			padding: 10px 0;
			font-weight: bold;
		}
	}
}

@media screen and (max-width: 960px) {
	.fa-caret-down {
		display: none;
	}
}

.nav-collapse {
	.Collapsible {
		width: 100%;
		.Collapsible__trigger {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.Collapsible__contentOuter {
			.Collapsible__contentInner {
				li {
					height: 50px;
					display: flex;
					align-items: center;
					.dropdown-link {
						text-decoration: none;
						color: #3f4ff8;
					}
				}
			}
		}
	}
}

.mobile-only {
	display: none;
	@media (max-width: 768px) {
		display: block;
	}
}

@media (max-width: 768px) {
	.desktop-only {
		display: none !important;
	}
}
