@import '../../constants/_variables.scss';

.button_style {
	font-family: 'Plus Jakarta Sans', sans-serif;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px;
	outline: none;
	border: none;
	background: $primaryColor;
	border-radius: 25px;
	color: $white;
	cursor: pointer;
	font-weight: bold;
	min-width: 150px;
	min-height: 60px;
	@media (max-width: 768px) {
		border-radius: 35px;
		font-size: 16px;
	}
}
