.home_page {
	background-image: url('../../assets/images/home-back.png');
	background-repeat: no-repeat;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.container {
		font-family: 'Plus Jakarta Sans', sans-serif;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		width: 60%;
		.title {
			font-family: 'Plus Jakarta Sans';
			font-size: 16px;
			font-weight: bold;
			/* identical to box height, or 375% */
			text-align: center;
			letter-spacing: -0.02em;
			text-transform: uppercase;

			color: #3f4ff8;
		}
		h1 {
			font-size: 50px;
			text-align: center;
		}
		.animate_text_area {
			display: flex;
			align-items: center;
			width: 80%;
			.Typewriter {
				font-family: 'Plus Jakarta Sans';
				font-size: 50px;
				text-align: center;
				font-weight: bold;
				margin-left: 10px;
				color: #3f4ff8;
			}
		}
		.home_button {
			padding: 30px;
			font-size: 20px;
			border-radius: 35px;
		}
	}

	@media (max-width: 768px) {
		.container {
			.animate_text_area {
				flex-direction: column;
			}
		}
	}
}

.leading_companies {
	padding-bottom: 100px;
	.left_area {
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.right_area {
		text-align: right;
		display: flex;
		align-items: center;
		.text_area {
			text-align: left;
			font-family: 'Plus Jakarta Sans';
			.title {
				span {
					color: #3f4ff8;
				}
			}
			.desc {
				color: #8181ac;
				margin: 20px 0;
			}
			@media (max-width: 768px) {
				padding-top: 20px;
			}
		}
	}
	@media (max-width: 768px) {
		margin: 0;
		padding: 20px 0;
	}
}

.our_clients {
	background-color: #282876;
	padding: 40px 0;
	margin: 50px 0;
	width: 100%;
	.title {
		text-align: center;
		color: #fff;
		span {
			color: #3f4ff8;
		}
	}
	.desc {
		font-size: 16px;
		font-family: 'Plus Jakarta Sans';
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.marquee_container {
		padding: 20px 0;
		.image_container {
			background-color: #ffffff;
			height: 60px;
			display: flex;
			justify-content: center;
			// padding: 10px;
			margin-right: 40px;
			min-width: 200px;
			border-radius: 10px;
		}
	}
	@media (max-width: 768px) {
		margin: 0;
		padding: 20px 0;
	}
}

.why_choose {
	padding: 40px 0;
	width: 100%;
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #3f4ff8;
		}
	}
	.desc {
		font-size: 16px;
		font-family: 'Plus Jakarta Sans';
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.feature_container {
		padding: 20px 0;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 20px;
		padding: 20px;
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			.image_container {
				background-color: #3f4ff8;
				height: 100px;
				width: 120px;
				display: flex;
				justify-content: center;
				padding: 10px;
				border-radius: 20px;
				align-items: center;
				img {
					width: 60px;
					height: 60px;
				}
			}
			.name {
				margin-top: 20px;
				font-size: 20px;
				color: #282876;
				text-align: center;
			}
		}
	}

	@media (max-width: 768px) {
		margin: 0;
		padding: 20px 0;
		.feature_container {
			grid-template-columns: repeat(3, 1fr);
			.container {
				.image_container {
					width: 60px;
					height: 60px;
					img {
						width: 30px;
						height: 30px;
					}
				}
				.name {
					font-size: 16px;
				}
			}
		}
	}
}

.featured_resources {
	padding: 40px 0;
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #3f4ff8;
		}
	}
	.desc {
		font-size: 16px;
		font-family: 'Plus Jakarta Sans';
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.feature_container {
		.container {
			padding: 20px;
			.image_name_container {
				box-shadow: -4px 4px 10px 0px #3f4ff81a;
				border-radius: 20px;
				min-height: 300px;
				cursor: pointer;
				.image_container {
					border-radius: 20px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					height: 180px;
					width: 100%;
					display: flex;
					justify-content: center;
					border-radius: 20px;
					align-items: center;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 20px;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
				.textArea {
					padding: 20px;
					background-color: #fff;
					border-radius: 20px;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					.top {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 10px;
						.tech {
							color: #8181ac;
						}
						.time {
							color: #8181ac;
						}
					}
					.name {
						margin-top: 20px;
						font-size: 16px !important;
						color: #282876 !important;
						font-family: 'Plus Jakarta Sans';
						font-weight: bold;
					}
					.read_more_container {
						display: flex;
						align-items: center;
						// justify-content: center;
						span {
							margin: 0;
							display: block;
							margin-right: 5px;
						}
					}
					.read_more {
						color: #3f4ff8;
						margin-top: 20px;
						font-size: 12px;
						text-align: right;
						font-weight: bold;
					}
				}
			}
		}
	}

	@media (max-width: 768px) {
		margin: 0;
		padding: 20px 0;
		.feature_container {
			.scroll_on_mobile {
				overflow-x: auto;
				flex-wrap: nowrap;
				gap: 0;
				margin: 0 !important;
				.container {
					padding: 20px;
					.image_name_container {
						width: 80vw;
					}
				}
			}
		}
	}
}

.our_services {
	padding: 40px 0;
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #3f4ff8;
		}
	}
	.desc {
		font-size: 16px;
		font-family: 'Plus Jakarta Sans';
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.feature_container {
		.container {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			.text_container {
				border-radius: 20px;
				background-color: #f3f3f3;
				width: 300px;
				height: 300px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 40px 20px;
				box-sizing: border-box;
				position: relative;
				overflow: hidden;
				cursor: pointer;
				transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
				.description {
					color: #8181ac;
					margin-top: 20px;
					font-size: 16px;
				}
				.arrow {
					position: absolute;
					bottom: 20px;
					right: 20px;
					color: #fff;
					z-index: -1;
				}
				&:hover {
					background-color: #272876;
					.description,
					.title {
						color: #fff;
					}
					.arrow {
						z-index: 1;
					}
					&::after {
						content: '';
						position: absolute;
						bottom: -100px;
						right: -100px;
						width: 200px;
						height: 200px;
						background: #3f4ff8;
						border-radius: 100%;
					}
				}
				&::after {
					content: '';
					position: absolute;
					bottom: -100px;
					right: -100px;
					width: 200px;
					height: 200px;
					background: #a0bdf1;
					border-radius: 100%;
				}
			}
		}
	}

	@media (max-width: 768px) {
		margin: 0;
		padding: 20px 0;
		.feature_container {
			.scroll_on_mobile {
				overflow-x: auto;
				flex-wrap: nowrap;
				gap: 0;
				margin: 0 !important;
				.container {
					padding: 20px;
					.image_name_container {
						width: 80vw;
					}
				}
			}
		}
	}
}

.to_support_us {
	padding: 40px 0;
	width: 100%;
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #3f4ff8;
		}
	}
	.desc {
		font-size: 16px;
		font-family: 'Plus Jakarta Sans';
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.feature_container {
		padding: 20px 0;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 20px;
		padding: 20px;
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			.image_container {
				cursor: pointer;
				box-shadow: 0px 0px 10px 0px #3f4ff840;
				height: 100px;
				width: 120px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 10px;
				border-radius: 10px;
				align-items: center;
				img {
					width: 60px;
					height: 60px;
				}
			}
			.back_image_container {
				cursor: pointer;
				box-shadow: 0px 0px 10px 0px #3f4ff840;
				height: 100px;
				width: 120px;
				padding: 10px;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				img {
					width: 60px;
					height: 60px;
				}
			}
			.name {
				margin-top: 10px;
				font-size: 20px;
				color: #3f4ff8;
				text-align: center;
			}
			.count {
				font-size: 40px;
				color: #3f4ff8;
				text-align: center;
			}
			.desc {
				text-align: center;
				width: 100%;
			}
		}
	}

	@media (max-width: 768px) {
		margin: 0;
		padding: 20px 0;
		.feature_container {
			padding: 10px;
			grid-template-columns: repeat(3, 0fr);
			justify-content: center;
			.container {
				.image_container,
				.back_image_container {
					width: 60px;
					height: 60px;
					img {
						width: 30px;
						height: 30px;
					}
				}
				.name,
				.count {
					font-size: 12px;
				}
				.desc {
					font-size: 10px;
				}
			}
		}
	}
}
