.our_mission {
	padding-bottom: 100px;
	padding-top: 20px;
	.left_area {
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.right_area {
		text-align: right;
		display: flex;
		align-items: center;
		.text_area {
			text-align: left;
			font-family: 'Plus Jakarta Sans';
			.title {
				span {
					color: #3f4ff8;
				}
			}
			.subtitle {
				display: block;
				margin-top: 20px;
				color: #3f4ff8;
				font-size: 16px;
				font-weight: bold;
				@media (max-width: 768px) {
					font-size: 16px;
				}
			}
			.desc {
				color: #8181ac;
				margin-top: 10px;
				line-height: 28px;
				@media (max-width: 768px) {
					line-height: 20px;
					font-size: 16px;
				}
			}
		}
	}
	@media (max-width: 768px) {
		margin: 0;
		padding-bottom: 20px;
	}
}

.core_values {
	// padding: 40px 0;
	width: 100%;
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #3f4ff8;
		}
	}
	.desc {
		font-size: 16px;
		font-family: 'Plus Jakarta Sans';
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.feature_container {
		padding: 20px 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		padding: 20px;
	}

	@media (max-width: 768px) {
		margin: 0;
		.feature_container {
			padding: 10px;
			grid-template-columns: repeat(1, 0fr);
			justify-content: center;
		}
	}
}

.engagement_model {
	// padding: 40px 0;
	width: 100%;
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #3f4ff8;
		}
	}
	.desc {
		font-size: 16px;
		font-family: 'Plus Jakarta Sans';
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.feature_container {
		padding: 20px 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 40px;
		padding: 20px;
		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			.image_container {
				box-sizing: border-box;
				padding: 30px;
				cursor: pointer;
				box-shadow: 0px 0px 10px 0px #3f4ff840;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				border-radius: 10px;
				align-items: center;
				min-height: 300px;
				img {
					width: 45px;
					height: 45px;
				}
			}
			.name {
				margin-top: 10px;
				font-size: 20px;
				color: #272876;
				text-align: center;
				margin-top: 20px;
				margin-bottom: 10px;
			}
			.count {
				font-size: 40px;
				color: #3f4ff8;
				text-align: center;
			}
			.desc {
				text-align: center;
				width: 100%;
				line-height: 28px;
			}
		}
	}

	@media (max-width: 768px) {
		margin: 0;
		.feature_container {
			padding: 10px;
			grid-template-columns: repeat(1, 1fr);
			justify-content: center;
			.container {
				.image_container {
					// min-height: fit-content;
					min-height: -moz-fit-content;
					min-height: fit-content;
				}
				.name,
				.count {
					font-size: 16px;
				}
				.desc {
					font-size: 16px;
					line-height: initial;
				}
			}
		}
	}
}

.our_approach {
	padding: 40px 0;

	.mobile {
		display: none;
	}
	.left_area {
		padding: 20px;
		.title {
			color: #3f4ff8;
			font-size: 36px;
			display: block;
		}
		.sub_title {
			font-size: 36px;
			font-weight: 600;
			display: block;
		}
		.description {
			font-family: 'Plus Jakarta Sans';
			font-size: 16px;
			color: #8181ac;
		}
	}

	.middle_area {
		display: flex;
		align-items: center;
		justify-content: center;
		.line {
			position: relative;
			height: 100%;
			width: 2px;
			background-color: #3f4ff8;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 0);
				width: 10px;
				height: 10px;
				background-color: #3f4ff8;
				border-radius: 100%;
			}
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, 0);
				width: 10px;
				height: 10px;
				background-color: #3f4ff8;
				border-radius: 100%;
			}
		}
	}

	// .right_area {
	// }

	.footer_text {
		padding: 20px 0;
		margin: 0;
		text-align: center;
		color: #282876;
		font-size: 24px;
		font-weight: 600;
		span {
			color: #3f4ff8;
		}
		@media (max-width: 768px) {
			font-size: 16px;
			padding-top: 0;
			margin-top: 0;
		}
	}

	.empty_block {
		min-height: 220px;
	}

	.text_content {
		min-height: 220px;
		padding-top: 40px;
		.image_container {
			position: relative;
			background: #3f4ff8;
			width: 80px;
			height: 80px;
			border-radius: 100% 100% 100% 40px;
			margin-bottom: 40px;
			box-shadow: 0px 15px 25px 0px #3f4ff840;
			.number {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: #fff;
				font-weight: 600;
				font-size: 30px;
			}
		}
		.content_container {
			.title {
				color: #3f4ff8;
				font-size: 20px;
				display: block;
				font-weight: 600;
			}
			.description {
				font-family: 'Plus Jakarta Sans';
				font-size: 16px;
				color: #8181ac;
				width: 80%;
			}
		}

		&.align_right {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			text-align: right;
			.image_container {
				border-radius: 100% 100% 40px 100%;
			}
			.content_container {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
			}
		}
		&.align_left {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			text-align: left;
		}
	}
	@media (max-width: 768px) {
		margin: 0;
		padding-bottom: 20px;
		.web {
			display: none;
		}
	}
}
