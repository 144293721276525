.technology {
	.footer_text {
		padding: 20px 0;
		text-align: center;
		color: #282876;
		font-size: 24px;
		font-weight: 600;
		span {
			color: #3f4ff8;
		}
		@media (max-width: 768px) {
			font-size: 18px;
			padding: 20px;
			padding-bottom: 0;
			margin-top: 0;
		}
	}

	@media (max-width: 768px) {
	}
}

.technology_showcase {
	padding: 40px 0;
	width: 100%;

	@media (max-width: 768px) {
		padding-top: 0;
	}
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #3f4ff8;
		}
	}
	.desc {
		font-size: 16px;
		font-family: 'Plus Jakarta Sans';
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.technology_list_container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		box-shadow: 0px 4px 5px 0px #0000001a;
		padding: 30px 0;
		.title_area {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@media (max-width: 768px) {
				width: 100%;
				justify-content: center !important;
			}
			.title {
				font-size: 24px;
				color: #3f4ff8;
				display: block;
				width: 50%;
				text-align: left;
				@media (max-width: 768px) {
					// width: 100%;
					text-align: center;
					font-size: 18px;
					margin-bottom: 20px;
				}
			}
		}

		.image_area {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-gap: 25px;

			@media (max-width: 768px) {
				margin-top: 20px;
				grid-template-columns: repeat(3, 1fr);
				width: 100%;
			}
			.image_container {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 10px;
				.image_box {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column; /* Ensure text doesn't stretch the image box */
					height: auto; /* Reset height */
					img {
						width: 60px;
						height: 60px;
						@media (max-width: 768px) {
							width: 45px;
							height: 45px;
						}
					}
				}
				.name {
					font-size: 16px;
					color: #8181ac;
					text-align: center;
					min-height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					@media (max-width: 768px) {
						font-size: 12px;
					}
				}
			}
		}
	}
	@media (max-width: 768px) {
		.technology_list_container {
			padding-top: 20px;
		}
	}
}
